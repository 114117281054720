<template>
  <div>
    <BranchForm :id="id" :visible="visible" ref="branchForm" />
  </div>
</template>

<script>
import BranchForm from "../component/branchForm.vue";
export default {
  name: "BranchDetail",
  components: { BranchForm },
  data() {
    return {
      id: 0,
      visible: false,
    };
  },
  mounted() {
    this.id = +this.$route.params.id;
    this.visible = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
